import styled from 'styled-components';
import { GlobalColors, Button } from '@quotalogic/ui';

export const Wrapper = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background: ${GlobalColors.bg.main.secondary};
  border-radius: 16px;
  box-shadow: 0px 2px 8px 0px #0000000A;
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: ${GlobalColors.text.primary};
`;

export const SubTitle = styled.span`
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: ${GlobalColors.text.secondary};
`;

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FooterLink = styled.div`
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
`;

export const Link = styled.a`
  padding: 0 8px;
  border-radius: 8px;
  line-height: 16px;
  font-weight: 500;
  color:  ${GlobalColors.text.accent};
`;

export const LinkButton = styled.a`
  font-size: 12px;
  font-weight: 500;
  color:  ${GlobalColors.text.accent};
  cursor: pointer;
`;

export const CheckBlock = styled.div`
  display: flex;
  gap: 8px;
  font-size: 12px;
  line-height: 16px;
  
  a {
    padding: 0;
  }
`;

export const ButtonStyle = styled(Button)`
  align-self: center;
  justify-content: center;
`;

export const TokenLabel = styled.div`
  display: flex;
  gap: 8px;
`;

export const ErrorLayout = styled.div`
  text-align: center;
  color: ${GlobalColors.text.error};
`;
