import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: ${GlobalColors.bg.main.primary};
`;

export const LogoStyle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;

export const ImageStyle = styled.img`
  object-fit: contain;
  width: 120px;
  height: 20px;
`;

export const ChildrenBlock = styled.div`
  display: flex;
`;

export const Footer = styled.div`
  text-align: center;
  font-size: 12px;
`;

export const FooterLinks = styled.div`
  display: flex;
  gap: 12px;
  font-weight: 500;
  color: ${GlobalColors.text.accent};
`;
