import { FC, ReactNode } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Wrapper, ChildrenBlock, Footer, FooterLinks, ImageStyle, LogoStyle } from './styles';

interface Props {
  logo?: string
  children: ReactNode
  name?: string
  website?: 'quotalogic.io' | 'quotcat.ru'
}

export const AccountLayout: FC<Props> = ({
  logo,
  children,
  name,
  website,
}) => {
  const { t } = useTranslation('authorization-footer');

  const links = [
    {
      url: `https://${website}/legal/privacy`,
      label: t('links.policy'),
    },
    {
      url: `https://${website}/legal/cookie-policy`,
      label: t('links.cookie'),
    },
    {
      url: `https://${website}/legal/terms-and-conditions`,
      label: t('links.terms'),
    },
  ];

  return (
    <Wrapper>
      <LogoStyle>
        {logo ? <ImageStyle alt="logo" src={logo} /> : name}
      </LogoStyle>

      <ChildrenBlock>
        {children}
      </ChildrenBlock>

      <Footer>
        <FooterLinks>
          {links?.map(({ url, label }) => (
            <Link href={url} key={url}>{label}</Link>
          ))}
        </FooterLinks>

        <div>{new Date().getFullYear()} {name}</div>
      </Footer>
    </Wrapper>
  );
};
